module.exports = function() {

  //top-menu
  (function($) {

    $.fn.menumaker = function(options) {

      var cssmenu = $(this),
        settings = $.extend({
          title: "Меню",
          format: "dropdown",
          sticky: false
        }, options);

      return this.each(function() {
        cssmenu.prepend('<div class="hamburger"><span></span></div>');
        // cssmenu.prepend('<div id="menu-button"><a href="#" class="hamburger"><span></span></a></div>');
        // cssmenu.prepend('<div id="menu-button">' + settings.title + '</div>');
        $(this).find(".hamburger").on('click', function() {
        // $(this).find("#menu-button").on('click', function() {
          $(this).toggleClass('on');
          $(this).toggleClass('menu-opened');
          var mainmenu = $(this).next('ul');
          if (mainmenu.hasClass('open')) {
            mainmenu.hide(500).removeClass('open');
          } else {
            mainmenu.show(500).addClass('open');
            if (settings.format === "dropdown") {
              mainmenu.find('ul').show();
            }
          }
        });

        cssmenu.find('li ul').parent().addClass('parent');

        multiTg = function() {
          cssmenu.find(".parent").prepend('<span class="submenu-button"></span>');
          cssmenu.find('.submenu-button').on('click', function() {
            $(this).toggleClass('submenu-opened');
            if ($(this).siblings('ul').hasClass('open')) {
              $(this).siblings('ul').removeClass('open').hide();
            } else {
              $(this).siblings('ul').addClass('open').show();
            }
          });
        };

        if (settings.format === 'multitoggle') multiTg();
        else cssmenu.addClass('dropdown');

        if (settings.sticky === true) cssmenu.css('position', 'fixed');

        resizeFix = function() {

          if ((window.innerWidth) > 1000) {
            cssmenu.find('ul').show();
          }

          if ((window.innerWidth) <= 1000) {
            cssmenu.find('ul').hide().removeClass('open');
          }
        };

        resizeFix();
        return $(window).on('resize', resizeFix);

      });
    };
  })(jQuery);

  (function($) {
    $(document).ready(function() {

      $(document).ready(function() {
        $("#hmenu").menumaker({
          title: "Меню",
          format: "multitoggle"
        });

        // $("#hmenu").prepend("<a href='#' 'menu-line' class='hamburger'><span></span></a>");
        $("#hmenu").prepend("<div id='menu-line'></div>");

        var foundActive = false,
          activeElement, linePosition = 0,
          menuLine = $("#hmenu #menu-line"),
          lineWidth, defaultPosition, defaultWidth;

        $("#hmenu > ul > li").each(function() {
          if ($(this).hasClass('active')) {
            activeElement = $(this);
            foundActive = true;
          }
        });

        if (foundActive === false) {
          activeElement = $("#hmenu > ul > li").first();
        }

        defaultWidth = lineWidth = activeElement.width();

        defaultPosition = linePosition = activeElement.position().left;

        menuLine.css("width", lineWidth);
        menuLine.css("left", linePosition);

        $("#hmenu > ul > li").hover(function() {
            activeElement = $(this);
            lineWidth = activeElement.width();
            linePosition = activeElement.position().left;
            menuLine.css("width", lineWidth);
            menuLine.css("left", linePosition);
          },
          function() {
            menuLine.css("left", defaultPosition);
            menuLine.css("width", defaultWidth);
          });

      });

    });
  })(jQuery);

};