module.exports = function() {

  //Animate CSS + WayPoints javaScript Plugin
  //Example: $(".element").animated("zoomInUp");
  //Author URL: http://webdesign-master.ru

  (function($) {
      $.fn.animated = function(inEffect) {
        $(this).css("opacity", "0").addClass("animated").waypoint(function(dir) {
          if (dir === "down") {
              $(this).addClass(inEffect).css("opacity", "1");
          };
        }, {
          offset: "70%"
        });
      };
  })(jQuery);

  //animate effect
  // $(".service__row .service__item:nth-child(odd)").animated("zoomInLeft", "zoomOutLeft");
  // $(".service__row .service__item:nth-child(even)").animated("zoomInRight", "zoomOutRight");
  $(".service__row .service__item:nth-child(odd)").animated("fadeInLeft", "fadeOutLeft");
  $(".service__row .service__item:nth-child(even)").animated("fadeInRight", "fadeOutRight");
  $(".objects-item-pic").animated("fadeInLeft", "fadeOutLeft");
  $(".objects-item-text").animated("fadeInRight", "fadeOutRight");
};