$(function() {

  // begin top-menu
  require('./modules/menu')();
  // end top-menu

  // begin top-menu__icon hamburger
  require('./modules/burger')();
  // end top-menu__icon hamburger

  // begin circle from index.html
  require('./modules/circle')();
  // end circle from index.html

  // begin SVG for IE
  require('./modules/svg4everybody')();
  // end   SVG for IE

  // begin animate.css
  require('./modules/waypoints')();
  require('./modules/animate-css')();
  // end   animate.css

  // begin object-one-slider
  require('./modules/object-one-slider')();
  // end   object-one-slider

});