module.exports = function() {

  //top-menu__icon hamburger
  $('.hamburger').on('click', function() {
    $(this).toggleClass('on');
    return false;
  });

  $(window).on('resize', function() {
    $('.hamburger').removeClass('on');
  });

};